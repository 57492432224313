import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Helmet from 'react-helmet'
import profilePic from '../images/about/geoff-roslyn-cafe.jpg'

export default function AboutPage({ data }) {
  return (
    <Layout>
      <Helmet>
        <title>About | {data.site.siteMetadata.title}</title>
        <link rel="canonical" href={data.site.siteMetadata.domain+data.sitePage.path} />
      </Helmet>
    <section className="content-body content-body--project py-5 py-lg-6 animated fadeIn">
      <article className="project-block row-g row-g--project">
        <div className="container-fluid">
          <section className="row pb-3 pb-lg-4">
            <div className="col-lg-12 px-0 pt-4 pt-lg-0">
              <img src={profilePic} alt="Roslyn Cafe"/>
              <p className="pt-2"><small><em>For the Northern Exposure fans out there.</em></small></p>
            </div>
          </section>
          <section className="row">
            <div className="col-lg-8 pl-0">         
              <h1 className="h2 pb-2 bold">I'm Geoff, a product designer and software tinkerer.</h1>
              <p>I currently lead product design at iManage Closing Folders. Previously, I worked in <a href="https://archive.geoffagnew.com/projects/bushtops" target="_blank" rel="noreferrer">boutique hospitality</a>, <a href="https://archive.geoffagnew.com/projects/university-of-toronto" target="_target" rel="noreferrer">higher education</a>, and <a href="https://geoffagnew.com/work/wave" target="_blank" rel="noreferrer">financial technology</a>. While a designer at heart, I enjoy dipping into the text editor and writing code whenever the occasion calls for it.</p>

              <p>In my free time I make things. <a href="https://thisweekinfood.ca/" target="_blank" rel="noreferrer">Software</a>, <a href="https://www.flickr.com/photos/15575848@N06/" target="_blank" rel="noreferrer">photography</a>, <a href="https://vimeo.com/236165694" target="_blank" rel="noreferrer">videos</a>, or whatever catches my interest. I'm currently building <a href="https://thisweekinfood.ca/" target="_blank" rel="noreferrer">This Week in Food</a>, a tool that takes the drudgery out of cooking.</p>

              <p>I no longer have a beard, and will probably never grow one again. They're more trouble than they're worth.</p>

            </div>
            <div className="col-lg-4 pt-3 pt-lg-0 pl-0 pl-lg-3">         
              <h3 className="h4">Find me elsewhere</h3>
              <ul className="list-nostyle">
                <li><a href="https://github.com/geoffagnew" target="_blank" rel="noreferrer">GitHub</a></li>
                <li><a href="https://ca.linkedin.com/in/geoffagnew" target="_blank" rel="noreferrer">LinkedIn</a></li>
              </ul>
              <h3 className="h4">Contact</h3>
              <p><a href="mailto:geoffagnew.dev@gmail.com">geoffagnew.dev@gmail.com</a></p>
            </div>
          </section>
        </div>

      </article>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        domain
      }
    },
    sitePage (path: {eq: "/about/"}) {
      path
    }
  }
`
